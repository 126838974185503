import React from 'react';
import meesmanFlowEN from '../../assets/images/meesman/meesman-flow.png';
import meesmanFlowNL from '../../assets/images/meesman/meesman-flow-nl.png';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const MeesmanFlow = () => {
  const { t, i18n } = useTranslation('meesman');
  return (
    <section className="section-4">
      <div className="container">
        <h3 className="section-4__title u-title-secondary">
          <Trans
            defaults="Why invest with <pink>Flow</pink>?"
            i18nKey="meesman:flowSection.title"
            components={{
              pink: <span className="u-color-violet-alt" />,
            }}
          />
        </h3>
        <div className="section-4__content">
          <p className="section-4__paragraph">
            {t(
              'flowSection.text_1',
              `With Flow, you can, for example, run the popular FI/RE (Financial Independence, Retire
            Early) money method automatically. An important part of this method is building more
            wealth quickly. Pay Yourself First (PYF) is a mentality that can help you achieve this.`
            )}
          </p>
          <div className="section-4__row">
            <div className="section-4__col">
              <img
                src={i18n.language === 'en' ? meesmanFlowEN : meesmanFlowNL}
                width="470"
                alt="meesman+flow"
                className="section-4__img"
              />
            </div>
            <div className="section-4__col">
              <p className="section-4__paragraph">
                {t(
                  'flowSection.text_2',
                  `Don't wait to see what's left at the end of the month, but take the steps towards
              wealth building proactively based on your income.`
                )}
              </p>
              <p className="section-4__paragraph">
                {t(
                  'flowSection.text_3',
                  `By the way, you don't have to start investing everything at once either, but can do
                  this in stages. That's perfect, because then you immediately apply Euro Cost
                  Averaging (ECA), or; spread investing. One time you invest at a "discount" when the
                  stock market is low, and another time you pay a little more when the stock market is
                  "high".`
                )}
              </p>
              <p className="section-4__paragraph">
                {t(
                  'flowSection.text_4',
                  `Over the long term, you then go along with a rise in the stock market. Flow's app
                  facilitates all this and that is unique. You can build up smart capital without even
                  realising it!`
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MeesmanFlow;
