import React from 'react';
import fire from '../../assets/images/meesman/fire-icon.svg';
import budget from '../../assets/images/meesman/budget-invest-icon.svg';
import auto from '../../assets/images/meesman/auto-invest-icon.svg';
import child from '../../assets/images/meesman/child-benefits-icon.svg';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { getFlowButtonHandler } from '../Dialog';

const MeesmanTemplates = () => {
  const { t } = useTranslation('meesman');
  return (
    <section className="section-5">
      <div className="container">
        <h2 className="section-5__title u-title-primary">
          {t('templatesSection.title', 'Get started with investment templates')}
        </h2>
        <p className="section-5__paragraph u-text-primary">
          <Trans
            defaults="We have already prepared <strong>4 money</strong> methods with which you can get started right away:"
            i18nKey="meesman:templatesSection.text"
            components={{
              bold: <strong />,
            }}
          />
        </p>
        <div className="section-5__content">
          <div className="section-5__card">
            <img src={fire} alt="fire" className="section-5__icon" />
            <div className="section-5__text">
              {t('templatesSection.card_text_1', 'FI/RE Meesman')}
            </div>
          </div>
          <div className="section-5__card">
            <img src={budget} alt="budget" className="section-5__icon" />
            <div className="section-5__text">
              {t('templatesSection.card_text_2', 'Budget & Invest')}
            </div>
          </div>
          <div className="section-5__card">
            <img src={auto} alt="auto" className="section-5__icon" />
            <div className="section-5__text">
              {t('templatesSection.card_text_3', 'Auto Invest')}
            </div>
          </div>
          <div className="section-5__card">
            <img src={child} alt="child" className="section-5__icon" />
            <div className="section-5__text">
              {t('templatesSection.card_text_4', 'Childs benefits Invested')}
            </div>
          </div>
        </div>
        <button onClick={() => getFlowButtonHandler()} className="btn btn--primary section-5__btn">
          {t('templatesSection.buttonTitle', 'Start using Meesman')}
        </button>
      </div>
    </section>
  );
};

export default MeesmanTemplates;
