import React from 'react';
import smiley from '../../assets/images/meesman/smiley.svg';
import like from '../../assets/images/meesman/like.svg';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const MeesmanStart = () => {
  const { t } = useTranslation('meesman');
  return (
    <section className="section-2">
      <div className="container">
        {<h2 className="section-2__title">
          {t('startSection.title', 'Investing, where to start?!')}
        </h2>}
        <div className="section-2__content">
          <div className="section-2__block">
            <img src={smiley} alt="smiley" className="section-2__img" />
            <p className="section-2__paragraph">
              {t(
                'startSection.column_1.text_1',
                `You'd like to build up something, but saving with the current interest rate and
              inflation isn't going to help.`
              )}
            </p>
            <p className="section-2__paragraph">
              {t(
                'startSection.column_1.text_2',
                `That's why many people are investing these days, and that can take many forms.`
              )}
            </p>
          </div>
          <div className="section-2__block">
            <img src={like} alt="like" className="section-2__img" />
            <p className="section-2__paragraph">
              {t(
                'startSection.column_2.text_1',
                `One form that takes little time and allows you to build up returns in a responsible
                way is index investing.`
              )}
            </p>
            <p className="section-2__paragraph">
              {t(
                'startSection.column_2.text_2',
                `That is why at Flow we work together with Meesman index investing. This combination
                offers unique opportunities to take control of your financial future.`
              )}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MeesmanStart;
