import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Facebook from './Facebook';
import Twitter from './Twitter';
import { SeoQuery } from '../../../gatsby-graphql';
import { PageProps } from 'gatsby';

// Complete tutorial: https://www.gatsbyjs.org/docs/add-seo-component/

interface Seo {
  page?: PageProps;
  homePage?: boolean;
  language?: string;
  title?: string;
  description?: string | null;
  banner?: string | null;
  path?: string;
  article?: boolean;
  node?: any;
}

const Seo = ({
  page,
  language,
  title,
  description,
  banner,
  path,
  article,
  node,
  homePage,
}: Seo) => {
  const { i18n } = useTranslation('header');
  const { site }: SeoQuery = useStaticQuery(query);

  const { buildTime, siteMetadata } = site!;
  const {
    siteUrl,
    defaultTitle,
    defaultDescription,
    defaultBanner,
    headline,
    author,
    twitter,
    facebook,
  } = siteMetadata!;

  const seo = {
    language: language || i18n.language,
    title: title || defaultTitle || 'Flow webpage',
    description: description || defaultDescription,
    image: banner || `${siteUrl}${defaultBanner}`,
    url: `${siteUrl}${path || page?.location?.pathname}`,
  };

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: siteUrl,
    headline,
    inLanguage: seo.language,
    mainEntityOfPage: siteUrl,
    description: defaultDescription,
    name: defaultTitle,
    author: {
      '@type': 'Person',
      name: author,
    },
    copyrightHolder: {
      '@type': 'Person',
      name: author,
    },
    copyrightYear: '2019',
    creator: {
      '@type': 'Person',
      name: author,
    },
    publisher: {
      '@type': 'Person',
      name: author,
    },
    datePublished: '2019-01-18T10:30:00+01:00',
    dateModified: buildTime,
    image: {
      '@type': 'ImageObject',
      url: `${siteUrl}${defaultBanner}`,
    },
  };

  // Initial breadcrumb list

  const itemListElement = [
    {
      '@type': 'ListItem',
      item: {
        '@id': siteUrl,
        name: 'Homepage',
      },
      position: 1,
    },
  ];

  let schemaArticle = null;

  if (article) {
    schemaArticle = {
      '@context': 'http://schema.org',
      '@type': 'Article',
      author: {
        '@type': 'Person',
        name: author,
      },
      copyrightHolder: {
        '@type': 'Person',
        name: author,
      },
      copyrightYear: '2019',
      creator: {
        '@type': 'Person',
        name: author,
      },
      publisher: {
        '@type': 'Organization',
        name: author,
        logo: {
          '@type': 'ImageObject',
          url: `${siteUrl}${defaultBanner}`,
        },
      },
      datePublished: node.first_publication_date,
      dateModified: node.last_publication_date,
      description: seo.description,
      headline: seo.title,
      inLanguage: seo.language,
      url: seo.url,
      name: seo.title,
      image: {
        '@type': 'ImageObject',
        url: seo.image,
      },
      mainEntityOfPage: seo.url,
    };
    // Push current blogpost into breadcrumb list
    itemListElement.push({
      '@type': 'ListItem',
      item: {
        '@id': seo.url,
        name: seo.title,
      },
      position: 2,
    });
  }

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    description: 'Breadcrumbs list',
    name: 'Breadcrumbs',
    itemListElement,
  };

  const seoTitle = !homePage
    ? `${seo.title} 💶 Flow Your Money`
    : `${seo.title} ${
        i18n.language === 'en'
          ? '⚙🏦 Automate your bank accounts'
          : '🧠💶 De Slimme Geldverdeler'
      }`;

  return (
    <>
      <Helmet title={seoTitle}>
        <html lang={seo.language} />
        <link rel="canonical" href={seo.url} />
        <meta name="description" content={seo.description!} />
        <meta name="image" content={seo.image} />
        {/* Insert schema.org data conditionally (webpage/article) + everytime (breadcrumbs) */}
        {/* {!article && (
          <script type="application/ld+json">
            {JSON.stringify(schemaOrgWebPage)}
          </script>
        )} */}
        {/* {article && (
          <script type="application/ld+json">
            {JSON.stringify(schemaArticle)}
          </script>
        )} */}
        {/* <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script> */}
        <script
          src="https://private.funnelll.com/scriptserver/scripts?id=cdb85e20-a4b1-423a-91aa-4a06393464c5"
          type="text/javascript"
          async
        ></script>
        <meta name="apple-itunes-app" content="app-id=1441919325" />
      </Helmet>
      <Facebook
        desc={seo.description!}
        image={seo.image}
        title={seoTitle}
        type={article ? 'article' : 'website'}
        url={seo.url}
        locale={seo.language}
        name={facebook!}
      />
      <Twitter
        title={seoTitle}
        image={seo.image}
        desc={seo.description!}
        username={twitter!}
      />
    </>
  );
};

export default Seo;

const query = graphql`
  query Seo {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        defaultTitle: title
        defaultDescription: description
        defaultBanner: banner
        headline
        author
        twitter
        facebook
      }
    }
  }
`;
