import React from 'react';
import { getFlowButtonHandler } from '../Dialog';
import heroImageEN from '../../assets/images/meesman/hero-image-alt.png';
import heroImageNL from '../../assets/images/meesman/hero-image-alt-nl.png';
import particle1 from '../../assets/images/meesman/particle-1.svg';
import particle2 from '../../assets/images/meesman/particle-2.svg';
import particle3 from '../../assets/images/meesman/particle-3.svg';
import particle4 from '../../assets/images/meesman/particle-4.svg';
import particle5 from '../../assets/images/meesman/particle-5.svg';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const MeesmanHero = () => {
  const { t, i18n } = useTranslation('meesman');
  return (
    <section className="section-1">
      <div className="container">
        <div className="section-1__content">
          <div className="section-1__block u-spacing-large">
            <h2 className="section-1__title u-title-secondary">
              {t('heroSection.title', 'Automate your investments')}
            </h2>
            <div className="section-1__text u-text-primary">
              <Trans
                defaults="<pink>Flow +</pink> <bold>Meesman</bold> let you set up your investing the way you like it. Set it, forget it and let your money grow."
                i18nKey="meesman:heroSection.text"
                components={{
                  pink: <span className="u-color-violet-alt" />,
                  bold: <span className="section-1__strong" />,
                }}
              />
            </div>
            <button className="btn btn--primary" onClick={() => getFlowButtonHandler()}>
              {t('heroSection.buttonTitle', 'Get started now')}
            </button>
          </div>
          <img
            src={i18n.language === 'en' ? heroImageEN : heroImageNL}
            width="550"
            alt="img"
            className="section-1__img"
          />
          <img src={particle1} alt="img" className="section-1__particle section-1__particle--1" />
          <img src={particle2} alt="img" className="section-1__particle section-1__particle--2" />
          <img src={particle3} alt="img" className="section-1__particle section-1__particle--3" />
          <img src={particle4} alt="img" className="section-1__particle section-1__particle--4" />
          <img src={particle5} alt="img" className="section-1__particle section-1__particle--5" />
          <img src={particle4} alt="img" className="section-1__particle section-1__particle--6" />
        </div>
      </div>
    </section>
  );
};

export default MeesmanHero;
