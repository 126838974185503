import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Layout from '../components/Layout';
import Seo from '../components/SEO/seo';
import MeesmanHero from '../components/meesman/MeesmanHero';
import MeesmanStart from '../components/meesman/MeesmanStart';
import MeesmanIndexes from '../components/meesman/MeesmanIndexes';
import MeesmanFlow from '../components/meesman/MeesmanFlow';
import MeesmanTemplates from '../components/meesman/MeesmanTemplates';

const Meesman = (page: PageProps) => {
  return (
    <Layout>
      <Seo page={page} title="Meesman" />
      <main className="page-meesman">
        <MeesmanHero />
        <MeesmanStart />
        <MeesmanIndexes />
        <MeesmanFlow />
        <MeesmanTemplates />
      </main>
    </Layout>
  );
};

export default Meesman;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
