import React from 'react';
import money from '../../assets/images/meesman/money-icon.svg';
import euro from '../../assets/images/meesman/eur-icon.svg';
import meesman from '../../assets/images/meesman/meesman-icon.svg';
import chain from '../../assets/images/meesman/chain-icon.svg';
import flow from '../../assets/images/meesman/flow-icon.svg';
import emoji from '../../assets/images/meesman/amazing-icon.svg';
import { useTranslation } from 'gatsby-plugin-react-i18next';

const MeesmanIndexes = () => {
  const { t } = useTranslation('meesman');
  return (
    <section className="section-3">
      <div className="container">
        <h2 className="section-3__title u-title-secondary">
          {t('indexesSection.title', 'Why invest in indexes with Meesman?')}
        </h2>
        <div className="section-3__content">
          <div className="section-3__row">
            <div className="section-3__col">
              <img src={money} alt="money" className="section-3__icon section-3__icon--money" />
              <p className="section-3__text">
                {t(
                  'indexesSection.text_1',
                  `Index investing with Meesman is transparent, simple, inexpensive and usually
                achieves a higher return at a lower risk compared to investing actively yourself.`
                )}
              </p>
            </div>
            <div className="section-3__col">
              <img src={euro} alt="euro" className="section-3__icon section-3__icon--euro" />
              <p className="section-3__text">
                {t(
                  'indexesSection.text_2',
                  `All Meesman index funds are broadly diversified, market-weighted, have low costs,
                  invest sustainably and can reclaim dividend tax.`
                )}
              </p>
            </div>
          </div>
          <div className="section-3__row">
            <div className="section-3__col">
              <img
                src={meesman}
                alt="meesman"
                className="section-3__icon section-3__icon--meesman"
              />
              <p className="section-3__text">
                {t(
                  'indexesSection.text_3',
                  `With the Shares World Total fund, Meesman offers a convenience solution. You don't
                  need to have a lot of (investment) knowledge.`
                )}
              </p>
            </div>
            <div className="section-3__col">
              <img src={chain} alt="chain" className="section-3__icon section-3__icon--chain" />
              <p className="section-3__text">
                {t(
                  'indexesSection.text_4',
                  `Meesman wants to tell the honest story about investing and make it possible for both
                  small and large investors to build up capital in a way that is objectively the best
                  for them.`
                )}
              </p>
            </div>
          </div>
          <div className="section-3__row">
            <div className="section-3__col">
              <img src={flow} alt="flow" className="section-3__icon section-3__icon--flow" />
              <p className="section-3__text">
                {t(
                  'indexesSection.text_5',
                  `In 2005, Meesman introduced index investing in the Netherlands; frontrunners just
                  like Flow!`
                )}
              </p>
            </div>
            <div className="section-3__col">
              <img src={emoji} alt="chain" className="section-3__icon section-3__icon--emoji" />
              <p className="section-3__text">
                {t(
                  'indexesSection.text_6',
                  `By the way, your money is safe and you're not tied down to anything!`
                )}
              </p>
            </div>
          </div>
        </div>
        <a
          href="https://flowyour.money/open-meesman"
          rel="noreferrer noopener"
          className="btn btn--primary section-3__btn"
        >
          {t('indexesSection.buttonTitle', 'Start using Meesman')}
        </a>
      </div>
    </section>
  );
};

export default MeesmanIndexes;
